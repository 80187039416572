import { Col,Image, Row } from "antd";
import Title from "antd/es/typography/Title";
import HtmlContent from "../HtmlContent";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../settings";

function ChairmanMessage() {
  const [type, setType] = useState("CHAIRMAN_MESSAGE");
  const [apiLoader, setApiLoader] = useState(false);
  const [messageId, setMessageId] = useState();
  const [messengerName, setMessengerName] = useState<string>();
  const [messengerPhotoName, setMessengerPhotoName] = useState<string>();
  const [messengerDesignation, setMessengerDesignation] = useState<string>();
  const [messages, setMessages] = useState<string>();

  const getMessageInfo = () => {
    setApiLoader(true);
    axios
      .get(`${API_URL}/company-messages-by-type/${type}`)
      .then((response) => {
        setApiLoader(false);
        setMessages(response.data.message);
        setMessageId(response.data.id);
        setMessengerName(response.data.messenger_name);
        setMessengerDesignation(response.data.messenger_designation);

        if (response.data.messenger_photo_name) {
          setMessengerPhotoName(
            API_URL + "/image-download/" + response.data.messenger_photo_name
          );
        }
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setApiLoader(false);
      });
  };

  useEffect(() => {
    getMessageInfo();
    return () => {};
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <Title style={{ textAlign: "center" }}>Chairman's Message </Title>
        </Col>
      </Row>
      <Row justify={"center"} align={"top"}>
      <Col
          className="default-text"
          style={{ textAlign: "center" }}
          xs={{ span: 22 }}
          sm={{ span: 22 }}
          md={{ span: 6}}
          lg={{ span: 6 }}
        >
          {messengerPhotoName && <Image src={messengerPhotoName} />}
          {messengerName && <Title>{messengerName}</Title>}
          {messengerDesignation && (
            <Title level={5}>{messengerDesignation}</Title>
          )}
        </Col>
        <Col
          xs={{ span: 22, offset: 2 }}
          sm={{ span: 22, offset: 2 }}
          md={{ span: 12, offset: 1 }}
          lg={{ span: 12, offset: 1 }}
        >
            <div className="default-text">
              <HtmlContent html={messages || ""} />
            </div>
        </Col>
      </Row>
    </>
  );
}

export default ChairmanMessage;
